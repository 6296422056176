@import url('https://fonts.googleapis.com/css?family=Manrope');
@import url('https://fonts.googleapis.com/css?family=Heebo');

@font-face {
    font-family: SF Pro Display;
    src: url(../../../../assests/fonts/SF-Pro-Display-Regular.ttf);
}

.container-card {
    margin: 0;
    max-width: 490px;
    width: 100%;
}

.div-contact-box {
    box-sizing: border-box;
    border: 1px solid #E7E6EB;
    border-radius: 8px;
    border: 1px solid #E7E6EB;
    background: #FFFFFF;
    transition: 0.3s;

}

.container-form {
    display: flex;
    flex-direction: column;
    margin: 40px 40px;
    text-align: left;
}

.field-title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    align-items: center;
    color: #564D6D;
}

.field-title1 {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    align-items: center;
    color: #564D6D;
    margin-top: 18px;

}

.input-field {
    background: #EAF0FE;
    border: 1px solid #D5E0FD;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px 16px;
    line-height: 16px;
    color: #564D6D;
    margin: 0px 0px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}

.input-field-message {
    background: #EAF0FE;
    border: 1px solid #D5E0FD;
    min-height: 120px;
    height: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px 16px;
    line-height: 16px;
    padding: 10px 10px 10px 10px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}

.div_button {
    margin-top: 40px;
    width: 100%;
    text-align: center;
}

.contact-button {
    padding: 0px 30px;
    min-width: 200px;
    height: 38px;
    background: #2D66F7;
    border: 0px;
    border-color: #2D66F7;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;

}

.div-error {
    color: red;
    font-size: 14px;
    margin-left: 6px;
    font-family: 'Manrope';
    font-style: normal;
}

.submit_button_title {
    flex-grow: 1;
    text-align: left;
    padding-left: 10px;
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
}