.card-shimmer-container {
    width:100%;
    min-height: 170px;
    max-width: 18.9rem;
    min-width: 14rem;
    border-radius: 8px;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    padding: 16px 16px;
    display: flex;
    cursor: pointer;
    border: 1px solid #E6E8EC;
    margin-bottom: 22px;
    flex-direction: column;
    gap: 12px;
}

.container-shimmer {
    display: flex;
    flex-direction: column;
    justify-items: center;
    text-align: center;
    align-items: center;
    gap: 10px;
}

.container-header {
    width: 160px;
    min-width: 150px;
    height: 18px;
    margin-left: 20px;
    margin-right: 20px;
}

.container-flag{
    height: 25px;
    width: 50px;
    border-radius: 8%;
}

.container-shimmer-button{
    width: 110px;
    height: 34px;
    border-radius: 6px;
    display: block !important;
    margin: 0 auto;

}

.div-flag{
    margin-top: 8px;
}
