@import url('https://fonts.googleapis.com/css?family=Manrope');
@import url('https://fonts.googleapis.com/css?family=Heebo');

.accordion-mobile {
    max-width: 900px;
    margin: 25px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .head {
    height: 100%;
    justify-items: center;
    justify-content: left;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    text-align: center;
  }
  
  .item {
    margin-bottom: 5px;
    padding: 5px 0px;
    font-size: 1rem;
    width: 100%;
    max-width: 628px;
  }
  
  .item .title-untoggle {
    background: linear-gradient(90.44deg, #2CC3D8 18.62%, #497AF9 175.12%);
    color: #fff;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    cursor: pointer;
    margin: 0 auto;
    padding: 12px 20px;
    box-shadow: 0px 4px 28px -4px rgba(66, 56, 95, 0.2);
    border-radius: 12px;
  
  }
  
  .item-accordain-title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;  
    color: #FFFFFF;
  }

  .accordain-icon{
    width: 24px;
    height: 24px;
  }
  
  
  .content {
    padding: auto 2rem;
    max-height: 0;
    overflow: hidden;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    text-align: left;
    font-size: 16px;
    transition: height .2s;
  
  }
  
  .content.show {
    padding: 16px 2rem;
    height: auto;
    max-height: 50vh;
    overflow: hidden;
    font-family: 'Heebo';
    text-align: left;
    font-style: normal;
    font-weight: 400;
    transition: height .2s;
  }
  