.btn {
  padding: 10px 20px;
  font-weight: 500;
  border-radius: 10px;
  margin: 1rem auto;
  letter-spacing: 1px;
  font-style: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

h4 {
  padding-left: 1rem;
  display: inline-block;
  font-style: normal;
}

.icon {
  font-weight: 900;
}

.btn:active .icon {
  transition: all .3s ease-in-out;
  transform: rotate(90deg);
}