@import url(https://fonts.googleapis.com/css?family=Manrope);
@import url(https://fonts.googleapis.com/css?family=Poppins);
@import url(https://fonts.googleapis.com/css?family=Manrope);
@import url(https://fonts.googleapis.com/css?family=Poppins);
@import url(https://fonts.googleapis.com/css?family=Poppins);
@import url(https://fonts.googleapis.com/css?family=Manrope);
@import url(https://fonts.googleapis.com/css?family=Manrope);
@import url(https://fonts.googleapis.com/css?family=Heebo);
@import url(https://fonts.googleapis.com/css?family=Manrope);
@import url(https://fonts.googleapis.com/css?family=Heebo);
@import url(https://fonts.googleapis.com/css?family=Manrope);
@import url(https://fonts.googleapis.com/css?family=Heebo);
@import url(https://fonts.googleapis.com/css?family=Manrope);
@import url(https://fonts.googleapis.com/css?family=Heebo);
@import url(https://fonts.googleapis.com/css?family=Manrope);
@import url(https://fonts.googleapis.com/css?family=Heebo);
@import url(https://fonts.googleapis.com/css?family=Manrope);
@import url(https://fonts.googleapis.com/css?family=Manrope);
@import url(https://fonts.googleapis.com/css?family=Manrope);
@import url(https://fonts.googleapis.com/css?family=Manrope);
@import url(https://fonts.googleapis.com/css?family=Heebo);
@import url(https://fonts.googleapis.com/css?family=Manrope);
@import url(https://fonts.googleapis.com/css?family=Heebo);
@import url(https://fonts.googleapis.com/css?family=Manrope);
@import url(https://fonts.googleapis.com/css?family=Heebo);
@import url(https://fonts.googleapis.com/css?family=Manrope);
@import url(https://fonts.googleapis.com/css?family=Heebo);
@import url(https://fonts.googleapis.com/css?family=Manrope);
@import url(https://fonts.googleapis.com/css?family=Manrope);
@import url(https://fonts.googleapis.com/css?family=Heebo);
@import url(https://fonts.googleapis.com/css?family=Heebo);
@import url(https://fonts.googleapis.com/css?family=Manrope);
@import url(https://fonts.googleapis.com/css?family=Heebo);
@import url(https://fonts.googleapis.com/css?family=Manrope);
@import url(https://fonts.googleapis.com/css?family=Manrope);
@import url(https://fonts.googleapis.com/css?family=Poppins);
@import url(https://fonts.googleapis.com/css?family=Manrope);
@import url(https://fonts.googleapis.com/css?family=Poppins);
@import url(https://fonts.googleapis.com/css?family=Manrope);
@import url(https://fonts.googleapis.com/css?family=Heebo);
@import url(https://fonts.googleapis.com/css?family=Manrope);
@import url(https://fonts.googleapis.com/css?family=Poppins);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
footer {
    background: linear-gradient(94.49deg, #170729 0%, #24082C 100%);
    height: 200px;
    width: 100%;
    bottom: 0%;
    position: static;
    text-align: center;
    margin: 0 0 -50px 0;
    padding: 0px 5.5%;
    text-align: -webkit-center;
    text-align: -moz-center;
}


.container-footer{
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 1276px;
    height: 200px;
}

.footer-left {
    text-align: center;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    flex-grow: 1;
}

.footer-right {
    text-align: center;
    align-items: center;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #613494;

}

.footer-logo {
    height: 33px;
    width: 33px;
    object-fit: cover;
    margin-right: 5px
}

.div-title-left {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    padding: 0px 5px;
    color: #FFFFFF;
}

.div-title-right {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: right;
    color: #B7B3C1;
    
}

.copyright {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    padding: 0px 0px;
    color: #B7B3C1;
}

.footer-policy {
    font-size: 16px;
    font-family: 'Manrope';
    font-style: normal;
    flood-color: #fff;
    color: white;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    padding-right: 16px;
    text-decoration: none;

}

.footer-contactus {
    font-size: 16px;
    font-family: 'Manrope';
    font-style: normal;
    flood-color: #fff;
    color: white;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    padding-left: 16px;
    text-decoration: none;
}
footer {
    background: linear-gradient(94.49deg, #170729 0%, #24082C 100%);
    height: 200px;
    width: 100%;
    bottom: 0%;
    position: static;
    text-align: center;
    margin: 0 0 -50px 0;
    padding: 0px 5.5%;
    text-align: -webkit-center;
    text-align: -moz-center;
}

.container-footer-mobile{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1276px;
    height: 200px;
    grid-gap:10px;
    gap:10px
}


.footer-top {
    text-align: center;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
}

.footer-bottom {
    text-align: center;
    align-items: center;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #613494;
}

.footer-logo {
    height: 33px;
    width: 33px;
    object-fit: cover;
    margin-right: 5px
}

.div-title-top {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    padding: 0px 5px;
    color: #FFFFFF;
}

.copyright-mobile {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    padding: 0px 0px;
    text-align: center;
    color: #B7B3C1;
}

.footer-policy {
    font-size: 16px;
    font-family: 'Manrope';
    font-style: normal;
    flood-color: #fff;
    color: white;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    padding-right: 16px;
    text-decoration: none;

}

.footer-contactus {
    font-size: 16px;
    font-family: 'Manrope';
    font-style: normal;
    flood-color: #fff;
    color: white;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    padding-left: 16px;
    text-decoration: none;
}
.header-container {
    padding-bottom: 0px;
    width: 100%;
    z-index: 4;
    top: 0%;
    left: 0%;
    right: 0%;
    display: inline-block;
    background: #FFFFFF;
    position: -webkit-sticky;
    position: sticky;
    text-align: -webkit-center;
    text-align: -moz-center;
    text-align: -moz-center;
    -moz-box-align: center;
    padding: 0px 5.5%;
    box-shadow: 0px 4px 16px rgba(35, 30, 36, 0.04);
}

.header-container-inner {
    align-items: center;
    display: flex;
    max-width: 1276px;
    justify-content: space-between;
    min-height: 60px;
    background: #FFFFFF;
}

.navbar-left {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #251A43;
    cursor: pointer
}

.logo-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #0F1825; 
}

.navbar-middle {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 1vw;
    gap: 1vw;
}

.navbar-middle-row-faq {
    min-width: 60px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #1A294F;
    text-decoration: none
}

.navbar-middle-row-privacy {
    margin-left: 15px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #1A294F;
    text-decoration: none
}

.nav-bar_end {
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
    text-align: center;
    grid-gap: 20px;
    gap: 20px;
    font-family: 'Manrope';
    font-style: normal;
    font-size: 15px;   
    font-weight: 600;
}

.privacy-container {
    position: relative;
    margin-top: 0px;
    max-width: 720px;
    padding: 0px 5.5%;
    display: inline-block;
}

.container-home-privacy {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
}

.container-date {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-top: 34px;
    color: #564D6D;
    text-align: left;
}

.privacy-policy-home {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #6E6681;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.privacy-policy {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #251A43;

}

.privacy-text-privacy {
    padding-top: 14px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #564D6D;
    text-align: left;
}

.privacy-text-title {
    text-align: left;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: #292435;
    margin-top: 32px;
    margin-bottom: 12px;
}

.privacy-text-subtitle{
    text-align: left;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: #564D6D;
    margin-top: 26px;
    margin-bottom: 12px; 
}

.text-contact-top {
    padding-top: 14px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    color: #564D6D;
    text-align: left;
    padding-bottom: 14px;
}

.text-contact-bottom {
    padding-top: 14px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    color: #564D6D;
    text-align: justify;
    padding-bottom: 100px;
}
.privacy-top-bar {
    background: url(/static/media/PrivacyPolicyPage.992c8311.svg) no-repeat center center/cover;
    width: 100%;
    padding-left: 11.5%;
    padding-right: 11.5%;
    display: flex;
    flex-direction: row;
}

.text-wrapper2 {
    width: 100%;
    padding: 60px 0px;
    text-align: center;
}

.text-header2 {
    width: 100%;
    left: 0px;
    top: 0px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    color: #FFFFFF;
    margin: 16px 0px;
    letter-spacing: 0;
}

.text-footer2 {
    width: 100%;
    top: 20px;
    margin: 16px 0px;
    justify-content: center;
    display: flex;
}

.text-footer2-inner{
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.04em;
    color: #9E99AB;
    max-width: 848px;
}
@font-face {
    font-family: SF Pro Display;
    src: url(/static/media/SF-Pro-Display-Regular.d2658de0.ttf);
}


.contact-container {
    background-color: #f6f8fb;
    margin-top: 0px;
    text-align: center;
    padding: 0 5.5%;

}

.container-home-contact {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding-top: 40px;
}


.contactus-home {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #6E6681;
    display: flex;
    align-items: center;
    flex-direction: row;
}


.text-contactus {
    font-family: 'Heebo';
    font-style: normal;
    font-size: 16px;
    display: flex;
    text-align: left;
    font-family: 'Manrope';
    font-weight: 600;
    color: #251A43;
}

.text-contact-top {
    padding-top: 14px;
    max-width: 490px;
    width: 100%;
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    color: #564D6D;
    text-align: center;
    padding-bottom: 14px;
}


.contact-bottom{
    padding-top: 14px;
    padding-bottom: 100px;
    justify-content: center;
    margin-top: 34px;
}
.contact-bottom-text{
    width: 100%;
    display: inline-block;
    max-width: 720px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #564D6D;
    text-align: justify;
}

@font-face {
    font-family: SF Pro Display;
    src: url(/static/media/SF-Pro-Display-Regular.d2658de0.ttf);
}

.container-card {
    margin: 0;
    max-width: 490px;
    width: 100%;
}

.div-contact-box {
    box-sizing: border-box;
    border: 1px solid #E7E6EB;
    border-radius: 8px;
    border: 1px solid #E7E6EB;
    background: #FFFFFF;
    transition: 0.3s;

}

.container-form {
    display: flex;
    flex-direction: column;
    margin: 40px 40px;
    text-align: left;
}

.field-title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    align-items: center;
    color: #564D6D;
}

.field-title1 {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    align-items: center;
    color: #564D6D;
    margin-top: 18px;

}

.input-field {
    background: #EAF0FE;
    border: 1px solid #D5E0FD;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px 16px;
    line-height: 16px;
    color: #564D6D;
    margin: 0px 0px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}

.input-field-message {
    background: #EAF0FE;
    border: 1px solid #D5E0FD;
    min-height: 120px;
    height: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px 16px;
    line-height: 16px;
    padding: 10px 10px 10px 10px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}

.div_button {
    margin-top: 40px;
    width: 100%;
    text-align: center;
}

.contact-button {
    padding: 0px 30px;
    min-width: 200px;
    height: 38px;
    background: #2D66F7;
    border: 0px;
    border-color: #2D66F7;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;

}

.div-error {
    color: red;
    font-size: 14px;
    margin-left: 6px;
    font-family: 'Manrope';
    font-style: normal;
}

.submit_button_title {
    flex-grow: 1;
    text-align: left;
    padding-left: 10px;
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
}
.Home {
  display: flex;
  bottom: 0%;
  top: 0%;
}

.dashboard-container {
    width: 100%;
    background-color:#f6f8fb;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}

.div-accordain{
    width: 100%;
}


img {
    width: 100%;
    border: none;
    object-fit: fill;
}

.banner-container{
    text-align: -webkit-center;
    text-align: -moz-center;
    align-items: flex-start;
}

.banner-container-inner {
    max-width: 1276px;
    text-align: left;
    margin: 0px 5.5%;
}

.frame2 {
    text-align: left;
    max-width: 592px;
    padding: 60px 0 80px;
    justify-items: left;
    align-items: flex-start;
    text-align: left;
    flex-grow: 1;
}

.banner-container-title {
    font-family: 'Manrope';
    font-weight: 600;
    font-style: normal;
    font-size: 30px;
    color: #251A43;
    display: flex;
    flex-direction: row;
}

.desc {
    font-family: 'Heebo';
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    text-align: start;
    color: #3D3358;
}

.div-spam {
    color: #1A63F4;
}
.div-visit-button{
    width: 100%;
    max-width: 180px;
    text-align: left;
    justify-content: left;

}
.visit-button {
    max-width: 180px;
    padding: 10px 20px;
    font-weight: 500;
    border-radius: 10px;
    letter-spacing: 1px;
    align-self: left;
    margin: 0px 0px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    text-align: left;
    color: #FFFFFF;

}
@font-face {
  font-family: SF Pro Display;
  src: url(/static/media/SF-Pro-Display-Regular.d2658de0.ttf);
}

.card:hover {
  -webkit-transform: translateY(-2px) scale(1.005) translateZ(0);
          transform: translateY(-2px) scale(1.005) translateZ(0);
  box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
    0 24px 46px var(--box-shadow-color);
}



.div-number-card-container {
  width:100%;
  min-height: 170px;
  max-width: 18.9rem;
  min-width: 14rem;
  border-radius: 8px;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  padding: 16px 16px;
  display: flex;
  cursor: pointer;
  border: 1px solid #E6E8EC;
  margin-bottom: 22px;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;
}

.div-card-container-inner{
  flex-direction: column;
  align-items:center;
  display:flex;
  text-align:center;
  grid-gap:10px;
  gap:10px
}


.number-title {
  flex-direction: column;
  align-items: center;
  display: flex;
  text-align: center, ;
  grid-gap: 6px;
  gap: 6px;
  font-family: 'SF Pro Display';
  color: #3D3358;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}

.div-sms-today {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #3D3358;
  text-transform: uppercase;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;
  min-width: 105px;
 height: 34px;
border: 1px solid #E7E6EB;
border-radius: 6px;
}
.card-shimmer-container {
    width:100%;
    min-height: 170px;
    max-width: 18.9rem;
    min-width: 14rem;
    border-radius: 8px;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    padding: 16px 16px;
    display: flex;
    cursor: pointer;
    border: 1px solid #E6E8EC;
    margin-bottom: 22px;
    flex-direction: column;
    grid-gap: 12px;
    gap: 12px;
}

.container-shimmer {
    display: flex;
    flex-direction: column;
    justify-items: center;
    text-align: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.container-header {
    width: 160px;
    min-width: 150px;
    height: 18px;
    margin-left: 20px;
    margin-right: 20px;
}

.container-flag{
    height: 25px;
    width: 50px;
    border-radius: 8%;
}

.container-shimmer-button{
    width: 110px;
    height: 34px;
    border-radius: 6px;
    display: block !important;
    margin: 0 auto;

}

.div-flag{
    margin-top: 8px;
}


.div-numberlist{
    max-width: 1276px;
    align-items: center;
}
.div-container-browser{
    max-width: 1276px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
}

.div-container-mobile{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.div-text {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    margin-top: 40px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: #3D3358;

}
.div_country {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    min-height: 30px;
}
.div-country-name{
    text-align: center;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
}
.top-bar-dahsboard {
    background: url(/static/media/MessagePage.963d6718.svg) no-repeat center center/cover;
    padding-bottom: 4.5%;
    padding: 4.5% 5.5% 4.5% 5.5%;
    text-align: -webkit-center;
    text-align: -moz-center;
}

.top-bar_inner {
    display: flex;
    max-width: 1276px;
    flex-direction: row;
}

.text-wrapper-browser {
    text-align: left;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.text-wrapper-mobile {
    padding-top: 8vh;
    text-align: center;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

h1 {
    font-size: 44px;
    background-color: #f3ec78;
    text-align: left;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    background-image: linear-gradient(90deg, #af6a42, #f3ec78);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
}

.text-header {
    max-width: 550px;
    left: 0px;
    top: 0px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    color: #FFFFFF;
    margin: 0px 0px;
    letter-spacing: 0;
}

.text-footer-browser {
    max-width: 616px;
    left: 0px;
    padding-right: 40px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.04em;
    color: #B9C7DF;
    margin: 0px 0px;
    text-align: left;
}

.text-footer-mobile {
    max-width: 616px;
    left: 0px;
    padding-right: 40px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 0px;
    letter-spacing: 0.04em;
    color: #B9C7DF;
    margin: 16px 0px;
    text-align: center;
    justify-content: center;
}

.right-box-component {
    display: flex;
    flex-direction: row;
}

.div-message-card-title {
    position: relative;
    max-width: 413px;
}



.div-container-whatsapp {
    width: 100%;
    left: 0px;
    top: 0px;
    text-align: start;
    max-lines: 2;
    font-family: 'Manrope';
    font-style: normal;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.04em;
    color: #0D002E;
}


.div-text-left {
    font-family: 'Manrope';
    height: 40px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    position: absolute;
    z-index: 2;
    top: 22px;
    left: 0px;
    align-items: center;
    background-color: #FFFFFF;
    margin-left: 32px;
    padding: 0px 8px;
    border-radius: 10px;
    grid-gap: 5px;
    gap: 5px;
    -webkit-transform: translateY(-2px) scale(1.005) translateZ(0);
            transform: translateY(-2px) scale(1.005) translateZ(0);
    box-shadow: 0 24px 46px rgba(0, 0, 0, 0.11), 0 24px 46px var(--box-shadow-color);
}


.div-number-border {
    max-width: 176px;
    width: 168px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: -2px;
    padding: 0px 10px;
    position: absolute;
    border: 1px solid #D7CCE4;
    top: 0px;
    right: 0px;
}

.div-number {
    max-width: 186px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: -2px;
    padding: 0px 10px;
    background-image: linear-gradient(to right, #2CC3D8, #497AF9);
    position: absolute;
    top: 4px;
    right: 4px;
}


.div-text-right {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.div-time-indicator {
    display: flex;
    flex-direction: column;
    margin-top: 10vh;
    align-items: flex-start;
}

.div-time-title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    padding-left: 10px;
    max-lines: 1;
    color: #FFFFFF;
}

.div-card {
    width: 100%;
    z-index: 2px;
    padding-top: 52px;
}

.div-card-container {
    min-height: 155px;
    max-width: 380px;
    background: #FFFFFFE5;
    padding: 10px 10px;
    border-radius: 10px;
    border: 3px solid #8D40A0;
    position: relative;
}

.divider {
    width: 100%;
    height: 0px;
    left: 0px;
    border: 1px solid #D7CCE4;
    order: 1;
    flex-grow: 0;
    margin: 5px 0px;
}
.number-list{
    max-width: 100%;
    text-align: -webkit-center;
    text-align: -moz-center;
    padding: 0px 5.5%; 
}

.div-hr-line-browser{
    color: #A6ACAF;
    background-color:#A6ACAF;
    width:100%;
    height: 1;
    margin: 0px 0px;
}


.div-hr-line-mobile{
    color: #A6ACAF;
    background-color:#A6ACAF;
    width:100%;
    height: 1;
    margin: 0px 0px;
}
.accordion-container {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 120px 5.5%;
}

.accordion {
  max-width: 1276px;
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  grid-gap: 24px;
  gap: 24px;
  justify-content: space-between;
}

.head {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  color: #3D3358;
}


.accordion-shimmer-container {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 120px 5.5%;
}

.head-shimmer {
    height: 60px;
    text-align: center;
    justify-content: center;
    margin-top: 60px;
}

.header {
    height: 45px;
    min-width: 45vw;
    border-radius: 6px;
}

.accordion-shimmer {
    max-width: 1276px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    grid-gap: 24px;
    gap: 24px;
}

.item-shimmer {
    margin-bottom: 5px;
    padding: 5px 10px;
    font-size: 1rem;
}

.title-shimmer {
    color: #fff;
    height: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin: 0 auto;
    box-shadow: 0px 4px 28px -4px rgba(66, 56, 95, 0.2);
    border-radius: 12px;
    width:100%;
    max-width: 625px;
}

.title-shimmer-mobile {
    color: #fff;
    height: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin: 0 auto;
    padding: 8px 20px;
    box-shadow: 0px 4px 28px -4px rgba(66, 56, 95, 0.2);
    border-radius: 12px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    min-width: 85vw;
}

.div-shimmer-mobile {
    margin: 0px 5.5%;
    margin-top: 60px;
}
.item {
    margin-bottom: 5px;
    padding: 5px 0px;
    font-size: 1rem;
    max-width: 628px;
  }
  
  .item .title-untoggle {
    background: linear-gradient(90.44deg, #2CC3D8 18.62%, #497AF9 175.12%);
    color: #fff;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: start;
    cursor: pointer;
    margin: 0 auto;
    padding: 12px 20px;
    box-shadow: 0px 4px 28px -4px rgba(66, 56, 95, 0.2);
    border-radius: 12px;
  
  }
  
  .item-accordain-title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;  
    color: #FFFFFF;
  }
  
  
  .content {
    padding: auto 2rem;
    max-height: 0;
    overflow: hidden;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    text-align: left;
    font-size: 16px;
    transition: height .2s;
  
  }
  
  .content.show {
    padding: 16px 2rem;
    height: auto;
    max-height: 50vh;
    overflow: hidden;
    font-family: 'Heebo';
    text-align: left;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    transition: height .2s;
  }

  .accordain-icon{
    width: 24px;
    height: 24px;
  }
.item {
    margin-bottom: 5px;
    padding: 5px 0px;
    font-size: 1rem;
    max-width: 628px;
  }
  
  .item .title-untoggle {
    background: linear-gradient(90.44deg, #2CC3D8 18.62%, #497AF9 175.12%);
    color: #fff;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    cursor: pointer;
    margin: 0 auto;
    padding: 12px 20px;
    box-shadow: 0px 4px 28px -4px rgba(66, 56, 95, 0.2);
    border-radius: 12px;
  
  }
  
  .item-accordain-title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;  
    color: #FFFFFF;
  }
  
  
  .content {
    padding: auto 2rem;
    max-height: 0;
    overflow: hidden;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    text-align: left;
    font-size: 16px;
    transition: height .2s;
  
  }
  
  .content.show {
    padding: 16px 2rem;
    height: auto;
    max-height: 50vh;
    overflow: hidden;
    font-family: 'Heebo';
    text-align: left;
    font-style: normal;
    font-weight: 400;
    transition: height .2s;
  }

  .accordain-icon{
    width: 24px;
    height: 24px;
  }
.accordion-mobile {
    max-width: 900px;
    margin: 25px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .head {
    height: 100%;
    justify-items: center;
    justify-content: left;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    text-align: center;
  }
  
  .item {
    margin-bottom: 5px;
    padding: 5px 0px;
    font-size: 1rem;
    width: 100%;
    max-width: 628px;
  }
  
  .item .title-untoggle {
    background: linear-gradient(90.44deg, #2CC3D8 18.62%, #497AF9 175.12%);
    color: #fff;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    cursor: pointer;
    margin: 0 auto;
    padding: 12px 20px;
    box-shadow: 0px 4px 28px -4px rgba(66, 56, 95, 0.2);
    border-radius: 12px;
  
  }
  
  .item-accordain-title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;  
    color: #FFFFFF;
  }

  .accordain-icon{
    width: 24px;
    height: 24px;
  }
  
  
  .content {
    padding: auto 2rem;
    max-height: 0;
    overflow: hidden;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    text-align: left;
    font-size: 16px;
    transition: height .2s;
  
  }
  
  .content.show {
    padding: 16px 2rem;
    height: auto;
    max-height: 50vh;
    overflow: hidden;
    font-family: 'Heebo';
    text-align: left;
    font-style: normal;
    font-weight: 400;
    transition: height .2s;
  }
  
.div-aboutus {
    text-align: -webkit-center;
    text-align: -moz-center;
    background: url(/static/media/background_aboutus.4dc48421.svg) no-repeat center center/cover;
    background-size: cover;
    padding: 120px 5.5%;
}

.div-aboutus-inner {
    max-width: 1276px;
    align-items: center;
    display: flex;
    flex-direction: row;
    text-align: center;
    padding: 0px 0px;
}

.div-aboutus-left-browser {
    flex-grow: 1;
    display: flex;
    max-width: 610px;
    margin-right: 60px;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
}

.div-aboutus-left-mobile {
    flex-grow: 1;
    display: flex;
    max-width: 638px;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.title-top {
    text-align: start;
    height: 17px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    color: #F39130;
    text-align: start;
}

.title-middle {
    display: flex;
    flex-direction: row;
    grid-gap: 12px;
    gap: 12px;
    margin-top: 12px;
}

.title-text-middle {
    width: 142px;
    height: 45px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    color: #F3F2F4;
}

.divider-container {
    margin: 30px 0px;
    text-align: start;
}

.div-line {
    width: 150px;
    height: 8px;
    border-radius: 25px;
    background: #F39130;
}

.aboutus-desc-browser {
    max-width: 600px;
    margin-right: 38px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #B9C7DF;
    text-align: start;
}

.aboutus-desc-mobile {
    max-width: 600px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #B9C7DF;
    text-align: center;
}

.div-aboutus-right {
    flex-grow: 1;
    max-width: 624px;
    margin-left: 14px;
    text-align: center;
}

.div-aboutus-card {
    width: 100%;
    position: relative;
    bottom: 40px;
    margin-top: 500px;
}

.div-aboutus-right-container {
    z-index: 2px;
    width: 549px;
    height: 350px;
    margin-top: 0px;
    background: url(/static/media/image-aboutus.17291ad2.svg) no-repeat center center/cover;
    border-radius: 10px;
    position: absolute;
    right: 20px;
    bottom: 20px;
}

.div-rectangle-white {
    width: 416px;
    height: 335px;
    background: url(/static/media/rectangle_brown.7c9b2353.svg) no-repeat center center/cover;
    position: absolute;
    z-index: 1px;
    right: 0px;
    bottom: 0px;
}
.div-component-header{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    text-align: center;
    color: #392d5c;
}
.div-component-title {
    border-radius: 25px;
    justify-content: center;
    align-items: center;
    padding: 8px 14px;
    max-width: 107px;
    height: 33px;
    background: #e8defb;
    border-radius: 20px;
}

.component-title {
    height: 17px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
    color: #9d68f3;
}
.div-testimonials {
    background-color: #FFFFFF;
    text-align: -webkit-center;
    text-align: -moz-center;
    margin-top: -12px;
    padding: 120px 5.5%;
    position: relative;

}

.div-testimonials-inner {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.div-testimonials-opinion {
    max-width: 100%;
    margin-bottom: 80px;
    margin-top: 60px;
    justify-content: center
}
.div-user-list {
    max-width: 1276px;
    justify-content: center;
    height: 80px;
}

.div-slider{
    width: 100%;
}
  
.div-user-tile {
    display: flex;
    flex-direction: column;
    width: 254px;
    height: 80px;
    box-shadow: -1px 0px 0px rgba(97, 97, 97, 0.06), 1px 0px 0px rgba(97, 97, 97, 0.06);
}

.div-user-tile-inner {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    padding: 16px 60px;
    grid-gap: 10px;
    gap: 10px;
    background-color: #FFFFFF;
}


.div-user-tile-inner-selected{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    padding: 16px 60px;
    grid-gap: 10px;
    gap: 10px;
    background-color: #F3F7FE;
}

.div-user-list-right-component {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    grid-gap: 12px;
    gap: 12px;
}

.div-user-name {
    min-width: 160px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #3D3358;
    text-align: left;
    max-lines: 1;
    overflow: visible;
}


.div-user-name-selected {
    min-width: 160px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #1A63F4;
    text-align: left;
    max-lines: 1;
    overflow: visible;
}


.div-user-company {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #3D3358;
    text-align: left;
    max-lines: 1;
    overflow: visible;

}

.div-user-company-selected {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #4882F6;
    text-align: left;
    max-lines: 1;
    overflow: visible;
}

.div-indicator{
    max-width: 17.8vw;
    height: 4px;
    background: #4882F6;
}

.div-indicator1{
    max-width: 254px;
    height: 4px;
    background: #4882F6;
}
.div-user-opinion {
  margin: 60px 0px;
  justify-content: center;
  align-items: center;
}
.card1 {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  align-items: center;
  background: #F6F9F3;
  box-shadow: 0px 24px 28px -4px rgba(66, 56, 95, 0.2);
  border-radius: 12px;
  max-width: 712px;
  padding: 0 2rem 2rem 2rem;
  border-radius: 10px;
}

.card2{
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  align-items: center;
  background: #F6F9F3;
  box-shadow: 0px 24px 28px -4px rgba(66, 56, 95, 0.2);
  width: 100%;
}

.div_opinion-line-top{
  width: 706px;
  height: 4px;
  margin-top: 1px;
  background: #4C9610;
  border-radius: 12px 12px 0px 0px;
}

.div_opinion-line-top2 {
  width: 100%;
  height: 4px;
  margin-top: 1px;
  background: #4C9610;
  border-radius: 12px 12px 0px 0px;
}

.div-opinion-container{
  flex-grow: 1; 
  display: flex;
  flex-direction: row; 
  padding: 44px 44px 44px 38px;
  grid-gap: 13px;
  gap: 13px;
}

.div-opinion-container-left{
  margin: 14px 4px
}

.div-opinion-container-right{
  display: flex;
  flex-direction: column;
  flex-grow: 1; 
  align-items: flex-start;
}


.div-text-opinion {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  color: #3D3358;
  text-align: left;
  flex-grow: 1;

}

.div-text-opinion2 {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  color: #3D3358;
  text-align: left;
  flex-grow: 1;

}


.div-text-user-details {
  display: flex;
  flex-direction: row;
  margin: 12px 0px;
}

.div-text-name {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #3D3358;
}

.div-text-company {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #1A63F4;
}

.div-text-designation {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #6E6681;
}

.div-avatar{
  width: 100%;
  text-align: left;
}

.div-features{
    text-align: -webkit-center;
    text-align: -moz-center;
    justify-content: center;
    padding: 118px 96px;
    
}

.div-features-inner{
    max-width: 1276px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.div-component-description {
    max-width: 537px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #847e94;
    margin: 8px 0px;

}
.div-feature-tile {
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    display: flex;
    flex-direction: row;
    grid-gap: 21px;
    gap: 21px;
    flex-grow: 1;
}

.div-feature-tile {
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    display: flex;
    flex-direction: row;
    grid-gap: 21px;
    gap: 21px;
    flex-grow: 1;
}


.div-feature-tile-indicator {
    width: 44px;
    height: 44px;
    background: linear-gradient(90.44deg, #2CC3D8 18.62%, #497AF9 175.12%);
    box-shadow: 0px 4px 28px -4px rgba(66, 56, 95, 0.2);
    border-radius: 12px;
    padding: 8px 16px;
}

.div-indicator-text {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #FFFFFF;
}

.div-feature-tile-content-container {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
    align-items: flex-start;
}

.div-feature-tile-title {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: #564D6D;
    text-align: start;

}

.div-feature-tile-description {
    font-family: 'Heebo';
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #6E6681;
    text-align: start;

}
.div-feature-list {
    max-width: 1276px;
    margin-top: 80px;
    padding: 0 1%;
}

.div-feature-row {
    grid-row-gap: 40px;
    row-gap: 40px;
    text-align: justify;
    text-justify: distribute-all-lines;
    grid-column-gap: 0px;
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
}

.div-feature-row .row {
    --bs-gutter-x: 0 !important;
    --bs-gutter-y: 0 !important;
}


.div-shimmer-row{
      grid-row-gap: 40px;
      row-gap: 40px;
      grid-column-gap: 0px;
      grid-column-gap: 0px;
      -webkit-column-gap: 0px;
              column-gap: 0px;
      text-align: justify;
      text-justify:distribute-all-lines ;
      padding: 0 1%;
}

.div-shimmer-row .row {
    --bs-gutter-x: 0 !important;
    --bs-gutter-y: 0 !important;
}

.div-feature-shimmer {
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    display: flex;
    flex-direction: row;
    grid-gap: 21px;
    gap: 21px;
    flex-grow: 1;
}

.div-feature-shimmer-indicator {
    width: 44px;
    height: 44px;
    box-shadow: 0px 4px 28px -4px rgba(66, 56, 95, 0.2);
    border-radius: 12px;
    padding: 8px 16px;
}



.div-feature-shimmer-content-container {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
    align-items: flex-start;
}

.div-feature-shimmer-title {
    width: 110px;
    height: 24px;
}

.div-feature-shimmer-description {
    flex-direction: column;
    display: flex;
    text-align: start;
    grid-gap: 6px;
    gap: 6px;
}

.div-feature-shimmer-description-tile{
    width: 18vw;
    height: 16px;
}
.div-process {
    text-align: -webkit-center;
    background: url(/static/media/process-background.4aed2213.svg) no-repeat center center/cover;
    background-size: cover;
    margin-top: 80px;
    text-align: -moz-center;
    justify-content: center;
    padding: 90px 5.5%;
}

.div-process-inner {
    max-width: 1276px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.div-process-title {
    justify-content: center;
    padding: 8px 14px;
    width: 85px;
    height: 33px;
    background: #390179;
    border-radius: 20px;
}

.title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 140%;
    text-transform: uppercase;
    color: #FFFFFF;
}

.div-process-header {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    color: #FFFFFF;
}

.div-process-steps-browser{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.div-process-steps-mobile{
    display: flex;
    flex-direction: column;
    grid-gap: 10;
    gap: 10;
    justify-content: center;
    align-items: center;
}


.div-process1-browser{
    max-width: 400px;
    max-height: 336px;
    margin-top: 77px;
}

.div-process1-mobile{
    max-width: 400px;
    max-height: 336px;
}

.div-process2{
    max-width: 400px;
    max-height: 336px;
}
.div-process3-browser{
    max-width: 400px;
    max-height: 336px;
    margin-top: 77px;
}

.div-process3-mobile{
    max-width: 400px;
    max-height: 336px;
}


.sms-container{
    bottom: 0%;
    top: 0%;
}
.top-bar-receivesms {
    background: url(/static/media/MessagePage.963d6718.svg) no-repeat center center/cover;
    /* background: linear-gradient(94.49deg, #170729 0%, #24082C 100%); */
    text-align: -webkit-center;
    text-align: -moz-center;
    padding: 0px 5.5%;
}

.box-container {
    max-width: 1276px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.topbar-receivesms-home {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
}

.div-number-container{
    align-items: center;
    margin-left: 20px;
}
.div-number-text {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.rightBoxComponent{
  display: flex;
  align-items: flex-start;
  margin: 80px 0px 120px 0px;
}

.outerBorderCircle {
  background: url(/static/media/outer_dotted_circle.ccf525ca.png) no-repeat center center/cover;
  height: 120px;
  width: 120px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerCircle{
  height: 31px;
  width: 31px;
}

.msgIcon {
  position: absolute;
  top: 10%;
  left: -5%;
  height: 30px;
  width: 30px;
}

.canadaFlag{
  position: absolute;
  right: -2%;
  bottom: 0%;
  height: 30px;
  width: 30px;
  object-fit: contain;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.left-box-component {
    margin: 80px 0px 0px 0px;
    color: #fff;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    align-items: flex-start;
}


.left-box-component1 {
    margin: 2rem 0rem;
    color: #fff;
    flex-grow: 1;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.left-box-component .main-text {
    text-align: left;
    margin-top: 1rem;
}

.left-box-component1 .main-text1 {
    text-align: center;
    justify-content: center;
    margin-top: 1rem;
}


.left-box-component .main-text .head {
    margin-bottom: 1rem;
}

.left-box-component1 .main-text1 .head1 {
    margin-bottom: 1rem;
    text-align: center;
}



.left-box-component .secondary-text {
    text-align: right;
}

.text-header {
    max-width: 848px;
    font-size: 48px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    color: #FFFFFF;
}

.text-footer {
    max-width: 848px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.04em;
    color: #B9C7DF;
}

.div-home-privacy{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 60px;
}

.container_smslist {
    background-color: #f6f8fb;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 5.5% 220px 5.5%;
}

.container_tabel {
    min-height: 375px;
    max-width: 1276px;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0px 5.5%;
}

.container_tabel1 {
    width:  100%;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.container-datatabel {
    min-height: 200px;
    box-shadow: 0 1px 4px 0 rgba(255, 255, 255, 0.2);
    padding: 8px 16px;
    background-color: white;
    border-radius: 4px;
    width: 100%;
    max-width: 1024px;
}
.container-datatabel1 {
    min-height: 200px;
    box-shadow: 0 1px 4px 0 rgba(255, 255, 255, 0.2);
    padding: 8px 16px;
    background-color: white;
    border-radius: 4px;
    width: 100%;
}

.container-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 9.5px 0px;
    width: 100%;
    max-width: 1024px;
}

.container-title1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 9.5px 0px;
    width: 100%;
}

.container-title-text-start {
    font-family: 'Manrope';
    font-size: 16px;
    color: #6E6681;
    padding-left: 15px;
    text-align: left;
    font-style: normal;
    font-family: 'Manrope';
    font-weight: 500;
    font-size: 16px;  
    width: 100%;
}

.container-title-text-middle {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    width: 100%;
    padding-left: 5px;
    text-align: left;
    color: #0D002E;
}

.container-button {
    width: 100%;
    text-align: end;
    height: 40px;
    padding-right: 3.4vw;

}

.container-text {
    margin-left: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    text-align: left;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #251A43;
}

th {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #251A43;
    text-align: left;
}

td {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-left: 50px;
    max-width: 150px;
    color: #564D6D;
    text-align: left;
}

.container-image {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 60px 0px;
    grid-gap: 12px;
    gap: 12px;
}

.img-empty {
    width: 44px;
    height: 44px;

}

.empty-msg {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #6E6681;
}

.div-title-empty-messsage {
    display: flex;
    padding: 0px;
    grid-gap: 1px;
    gap: 1px;
    width: 100%;
    max-width: 1024px;
    background: #F3F2F4;
   box-shadow: 0px -1px 0px rgba(136, 136, 136, 0.25), 0px 1px 0px rgba(136, 136, 136, 0.25);
}

.div-title-empty-messsage1 {
    display: flex;
    padding: 0px;
    grid-gap: 1px;
    gap: 1px;
    width: 100%;
    background: #F3F2F4;
   box-shadow: 0px -1px 0px rgba(136, 136, 136, 0.25), 0px 1px 0px rgba(136, 136, 136, 0.25);
}
.btn {
  padding: 10px 20px;
  font-weight: 500;
  border-radius: 10px;
  margin: 1rem auto;
  letter-spacing: 1px;
  font-style: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

h4 {
  padding-left: 1rem;
  display: inline-block;
  font-style: normal;
}

.icon {
  font-weight: 900;
}

.btn:active .icon {
  transition: all .3s ease-in-out;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.container_shimmer{
    background-color: #f6f8fb;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 5.5%;

}

.container_shimmer_tabel{
    min-height: 200px;
    display: flex;
    flex-direction: column;
    max-width: 1276px;
    width: 100%;
    align-items:center;
}

.container_shimmer_tabel1{
    min-height: 200px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items:center;
}


.container-datatabel-shimmer{
    min-height: 200px;
    box-shadow: 0 1px 4px 0 rgba(255, 255, 255, 0.2);
    background-color: white;
    border-radius: 4px;
    padding: 8px 16px;
    width: 100%;
    max-width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .container-datatabel-shimmer1{
    min-height: 200px;
    box-shadow: 0 1px 4px 0 rgba(255, 255, 255, 0.2);
    background-color: white;
    border-radius: 4px;
    padding: 8px 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  
.container-shimmer_title{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 9.6px 0px;
    width: 100%;
    max-width: 1024px;
}
.container-shimmer_title1{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 9.6px 0px;
    width: 100%;
}
.container-shimmer_table_title{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    padding: 8.5px 0px;
}
.container-shimmer_table_title1{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    grid-gap: 20px;
    gap: 20px;
    padding: 8.5px 0px;

}
.container-shimmer_table_title1{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 8.5px 0px;

}

.shimmer-dropdown{
    width:120px; 
    text-align: left;
    margin-left: px;
    display: flex;
    flex-direction: column;
}

.shimmer-dropdown1{
    width:120px; 
    text-align: left;
    display: flex;
    flex-direction: column;
}

.div-dropdown-shimmer{
    width:145px; 
    height: 30px;
    margin-top: 3px;
    margin-left: 8px;
}

.shimmer-title-text{  
    width:100%; 
    text-align: left;
    margin-left: 5px;
    height: 33px;
    padding: 6px 0px;
}

.shimmer-title-text-start{
    width:100%; 
    text-align: left;
    margin-left: 5px;
    height: 33px;
    padding: 6px 10px; 
}

.div-dropdown-title{
    height: 14px;
    border-radius: 4px;
    max-width:90px ;
 }
 
.div-title-shimmer{
    height: 16px;
    min-width:100% ;
    margin-right: 8px;
 }

 .shimmer-title-text-mid{
    flex-grow: 1;
 }

 .div-title-shimmer1{
    height: 14px;
    min-width:120px ;
    margin-right: 8px;
 }

 .shimmer-title-text-end{
    width: 100%;
    height: 40px;
    text-align: right;
 }

 .div-title-end{
    min-width: 115px;
    height: 35px;
    margin-right: 3.4vw;
 }


 
 .div-title-search{
    min-width: 165px;
    max-width:55px;
    height: 30px;
 }

 .div-title-search1{
    min-width: 105px;
    height: 30px;
  }
 

 .div-text-end{
    min-width:110px;
    height: 16px;
    border-radius: 4px;
 }

.shimmer_table_title{
    font-style: normal;
    width: 100%;
    justify-content: center;
}

.shimmer_table_title-end{
    font-style: normal;
    width: 100%;
    justify-content: center;
}





.shimmer_table_row{
    font-style: normal;
    width: 100%;
    height: 16px;
    justify-content: center;
}
