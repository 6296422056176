

.div-shimmer-row{
      row-gap: 40px;
      grid-column-gap: 0px;
      column-gap: 0px;
      text-align: justify;
      text-justify:distribute-all-lines ;
      padding: 0 1%;
}

.div-shimmer-row .row {
    --bs-gutter-x: 0 !important;
    --bs-gutter-y: 0 !important;
}

.div-feature-shimmer {
    width:fit-content;
    display: flex;
    flex-direction: row;
    gap: 21px;
    flex-grow: 1;
}

.div-feature-shimmer-indicator {
    width: 44px;
    height: 44px;
    box-shadow: 0px 4px 28px -4px rgba(66, 56, 95, 0.2);
    border-radius: 12px;
    padding: 8px 16px;
}



.div-feature-shimmer-content-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
}

.div-feature-shimmer-title {
    width: 110px;
    height: 24px;
}

.div-feature-shimmer-description {
    flex-direction: column;
    display: flex;
    text-align: start;
    gap: 6px;
}

.div-feature-shimmer-description-tile{
    width: 18vw;
    height: 16px;
}