.dashboard-container {
    width: 100%;
    background-color:#f6f8fb;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}

.div-accordain{
    width: 100%;
}

