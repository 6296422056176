@import url('https://fonts.googleapis.com/css?family=Heebo');

.div-feature-tile {
    width:fit-content;
    display: flex;
    flex-direction: row;
    gap: 21px;
    flex-grow: 1;
}

.div-feature-tile {
    width:fit-content;
    display: flex;
    flex-direction: row;
    gap: 21px;
    flex-grow: 1;
}


.div-feature-tile-indicator {
    width: 44px;
    height: 44px;
    background: linear-gradient(90.44deg, #2CC3D8 18.62%, #497AF9 175.12%);
    box-shadow: 0px 4px 28px -4px rgba(66, 56, 95, 0.2);
    border-radius: 12px;
    padding: 8px 16px;
}

.div-indicator-text {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #FFFFFF;
}

.div-feature-tile-content-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
}

.div-feature-tile-title {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: #564D6D;
    text-align: start;

}

.div-feature-tile-description {
    font-family: 'Heebo';
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #6E6681;
    text-align: start;

}