@import url('https://fonts.googleapis.com/css?family=Manrope');

.div-container-browser{
    max-width: 1276px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
}

.div-container-mobile{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.div-text {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    margin-top: 40px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: #3D3358;

}
.div_country {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    min-height: 30px;
}