.privacy-top-bar {
    background: url('./../../../assests/topbar/PrivacyPolicyPage.svg') no-repeat center center/cover;
    width: 100%;
    padding-left: 11.5%;
    padding-right: 11.5%;
    display: flex;
    flex-direction: row;
}

.text-wrapper2 {
    width: 100%;
    padding: 60px 0px;
    text-align: center;
}

.text-header2 {
    width: 100%;
    left: 0px;
    top: 0px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    color: #FFFFFF;
    margin: 16px 0px;
    letter-spacing: 0;
}

.text-footer2 {
    width: 100%;
    top: 20px;
    margin: 16px 0px;
    justify-content: center;
    display: flex;
}

.text-footer2-inner{
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.04em;
    color: #9E99AB;
    max-width: 848px;
}