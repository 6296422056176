@import url('https://fonts.googleapis.com/css?family=Manrope');
@import url('https://fonts.googleapis.com/css?family=Poppins');
.rightBoxComponent{
  display: flex;
  align-items: flex-start;
  margin: 80px 0px 120px 0px;
}

.outerBorderCircle {
  background: url('./../../../../assests/receivesms/outer_dotted_circle.png') no-repeat center center/cover;
  height: 120px;
  width: 120px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerCircle{
  height: 31px;
  width: 31px;
}

.msgIcon {
  position: absolute;
  top: 10%;
  left: -5%;
  height: 30px;
  width: 30px;
}

.canadaFlag{
  position: absolute;
  right: -2%;
  bottom: 0%;
  height: 30px;
  width: 30px;
  object-fit: contain;
}
