.accordion-shimmer-container {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 120px 5.5%;
}

.head-shimmer {
    height: 60px;
    text-align: center;
    justify-content: center;
    margin-top: 60px;
}

.header {
    height: 45px;
    min-width: 45vw;
    border-radius: 6px;
}

.accordion-shimmer {
    max-width: 1276px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.item-shimmer {
    margin-bottom: 5px;
    padding: 5px 10px;
    font-size: 1rem;
}

.title-shimmer {
    color: #fff;
    height: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin: 0 auto;
    box-shadow: 0px 4px 28px -4px rgba(66, 56, 95, 0.2);
    border-radius: 12px;
    width:100%;
    max-width: 625px;
}

.title-shimmer-mobile {
    color: #fff;
    height: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin: 0 auto;
    padding: 8px 20px;
    box-shadow: 0px 4px 28px -4px rgba(66, 56, 95, 0.2);
    border-radius: 12px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    min-width: 85vw;
}

.div-shimmer-mobile {
    margin: 0px 5.5%;
    margin-top: 60px;
}