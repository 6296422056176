@import url('https://fonts.googleapis.com/css?family=Heebo');

.div-user-tile {
    display: flex;
    flex-direction: column;
    width: 254px;
    height: 80px;
    box-shadow: -1px 0px 0px rgba(97, 97, 97, 0.06), 1px 0px 0px rgba(97, 97, 97, 0.06);
}

.div-user-tile-inner {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    padding: 16px 60px;
    gap: 10px;
    background-color: #FFFFFF;
}


.div-user-tile-inner-selected{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    padding: 16px 60px;
    gap: 10px;
    background-color: #F3F7FE;
}

.div-user-list-right-component {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    gap: 12px;
}

.div-user-name {
    min-width: 160px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #3D3358;
    text-align: left;
    max-lines: 1;
    overflow: visible;
}


.div-user-name-selected {
    min-width: 160px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #1A63F4;
    text-align: left;
    max-lines: 1;
    overflow: visible;
}


.div-user-company {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #3D3358;
    text-align: left;
    max-lines: 1;
    overflow: visible;

}

.div-user-company-selected {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #4882F6;
    text-align: left;
    max-lines: 1;
    overflow: visible;
}

.div-indicator{
    max-width: 17.8vw;
    height: 4px;
    background: #4882F6;
}

.div-indicator1{
    max-width: 254px;
    height: 4px;
    background: #4882F6;
}