.accordion-container {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 120px 5.5%;
}

.accordion {
  max-width: 1276px;
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: space-between;
}

.head {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  color: #3D3358;
}

