@import url('https://fonts.googleapis.com/css?family=Manrope');

@font-face {
  font-family: SF Pro Display;
  src: url(../../../../../assests/fonts/SF-Pro-Display-Regular.ttf);
}

.card:hover {
  transform: translateY(-2px) scale(1.005) translateZ(0);
  box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
    0 24px 46px var(--box-shadow-color);
}



.div-number-card-container {
  width:100%;
  min-height: 170px;
  max-width: 18.9rem;
  min-width: 14rem;
  border-radius: 8px;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  padding: 16px 16px;
  display: flex;
  cursor: pointer;
  border: 1px solid #E6E8EC;
  margin-bottom: 22px;
  flex-direction: column;
  gap: 12px;
}

.div-card-container-inner{
  flex-direction: column;
  align-items:center;
  display:flex;
  text-align:center;
  gap:10px
}


.number-title {
  flex-direction: column;
  align-items: center;
  display: flex;
  text-align: center, ;
  gap: 6px;
  font-family: 'SF Pro Display';
  color: #3D3358;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}

.div-sms-today {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #3D3358;
  text-transform: uppercase;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;
  min-width: 105px;
 height: 34px;
border: 1px solid #E7E6EB;
border-radius: 6px;
}