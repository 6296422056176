@import url('https://fonts.googleapis.com/css?family=Manrope');
@import url('https://fonts.googleapis.com/css?family=Poppins');
.top-bar-receivesms {
    background: url('./../../../assests/topbar/MessagePage.svg') no-repeat center center/cover;
    /* background: linear-gradient(94.49deg, #170729 0%, #24082C 100%); */
    text-align: -webkit-center;
    text-align: -moz-center;
    padding: 0px 5.5%;
}

.box-container {
    max-width: 1276px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.topbar-receivesms-home {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    align-items: center;
    gap: 4px;
}

.div-number-container{
    align-items: center;
    margin-left: 20px;
}
.div-number-text {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}
