@import url('https://fonts.googleapis.com/css?family=Manrope');


.div-component-description {
    max-width: 537px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #847e94;
    margin: 8px 0px;

}