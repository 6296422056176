@import url('https://fonts.googleapis.com/css?family=Manrope');
@import url('https://fonts.googleapis.com/css?family=Heebo');


.div-aboutus {
    text-align: -webkit-center;
    text-align: -moz-center;
    background: url('./../../assests//aboutus/background_aboutus.svg') no-repeat center center/cover;
    background-size: cover;
    padding: 120px 5.5%;
}

.div-aboutus-inner {
    max-width: 1276px;
    align-items: center;
    display: flex;
    flex-direction: row;
    text-align: center;
    padding: 0px 0px;
}

.div-aboutus-left-browser {
    flex-grow: 1;
    display: flex;
    max-width: 610px;
    margin-right: 60px;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
}

.div-aboutus-left-mobile {
    flex-grow: 1;
    display: flex;
    max-width: 638px;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.title-top {
    text-align: start;
    height: 17px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    color: #F39130;
    text-align: start;
}

.title-middle {
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-top: 12px;
}

.title-text-middle {
    width: 142px;
    height: 45px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    color: #F3F2F4;
}

.divider-container {
    margin: 30px 0px;
    text-align: start;
}

.div-line {
    width: 150px;
    height: 8px;
    border-radius: 25px;
    background: #F39130;
}

.aboutus-desc-browser {
    max-width: 600px;
    margin-right: 38px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #B9C7DF;
    text-align: start;
}

.aboutus-desc-mobile {
    max-width: 600px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #B9C7DF;
    text-align: center;
}

.div-aboutus-right {
    flex-grow: 1;
    max-width: 624px;
    margin-left: 14px;
    text-align: center;
}

.div-aboutus-card {
    width: 100%;
    position: relative;
    bottom: 40px;
    margin-top: 500px;
}

.div-aboutus-right-container {
    z-index: 2px;
    width: 549px;
    height: 350px;
    margin-top: 0px;
    background: url('./../../assests//aboutus/image-aboutus.svg') no-repeat center center/cover;
    border-radius: 10px;
    position: absolute;
    right: 20px;
    bottom: 20px;
}

.div-rectangle-white {
    width: 416px;
    height: 335px;
    background: url('./../../assests//aboutus/rectangle_brown.svg') no-repeat center center/cover;
    position: absolute;
    z-index: 1px;
    right: 0px;
    bottom: 0px;
}