
.div-features{
    text-align: -webkit-center;
    text-align: -moz-center;
    justify-content: center;
    padding: 118px 96px;
    
}

.div-features-inner{
    max-width: 1276px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}