@import url('https://fonts.googleapis.com/css?family=Manrope');
@import url('https://fonts.googleapis.com/css?family=Heebo');
img {
    width: 100%;
    border: none;
    object-fit: fill;
}

.banner-container{
    text-align: -webkit-center;
    text-align: -moz-center;
    align-items: flex-start;
}

.banner-container-inner {
    max-width: 1276px;
    text-align: left;
    margin: 0px 5.5%;
}

.frame2 {
    text-align: left;
    max-width: 592px;
    padding: 60px 0 80px;
    justify-items: left;
    align-items: flex-start;
    text-align: left;
    flex-grow: 1;
}

.banner-container-title {
    font-family: 'Manrope';
    font-weight: 600;
    font-style: normal;
    font-size: 30px;
    color: #251A43;
    display: flex;
    flex-direction: row;
}

.desc {
    font-family: 'Heebo';
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    text-align: start;
    color: #3D3358;
}

.div-spam {
    color: #1A63F4;
}