@import url('https://fonts.googleapis.com/css?family=Manrope');
@import url('https://fonts.googleapis.com/css?family=Heebo');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.left-box-component {
    margin: 80px 0px 0px 0px;
    color: #fff;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    align-items: flex-start;
}


.left-box-component1 {
    margin: 2rem 0rem;
    color: #fff;
    flex-grow: 1;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.left-box-component .main-text {
    text-align: left;
    margin-top: 1rem;
}

.left-box-component1 .main-text1 {
    text-align: center;
    justify-content: center;
    margin-top: 1rem;
}


.left-box-component .main-text .head {
    margin-bottom: 1rem;
}

.left-box-component1 .main-text1 .head1 {
    margin-bottom: 1rem;
    text-align: center;
}



.left-box-component .secondary-text {
    text-align: right;
}

.text-header {
    max-width: 848px;
    font-size: 48px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    color: #FFFFFF;
}

.text-footer {
    max-width: 848px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.04em;
    color: #B9C7DF;
}
