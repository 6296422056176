.div-feature-list {
    max-width: 1276px;
    margin-top: 80px;
    padding: 0 1%;
}

.div-feature-row {
    row-gap: 40px;
    text-align: justify;
    text-justify: distribute-all-lines;
    grid-column-gap: 0px;
    column-gap: 0px;
}

.div-feature-row .row {
    --bs-gutter-x: 0 !important;
    --bs-gutter-y: 0 !important;
}