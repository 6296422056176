
.container_shimmer{
    background-color: #f6f8fb;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 5.5%;

}

.container_shimmer_tabel{
    min-height: 200px;
    display: flex;
    flex-direction: column;
    max-width: 1276px;
    width: 100%;
    align-items:center;
}

.container_shimmer_tabel1{
    min-height: 200px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items:center;
}


.container-datatabel-shimmer{
    min-height: 200px;
    box-shadow: 0 1px 4px 0 rgba(255, 255, 255, 0.2);
    background-color: white;
    border-radius: 4px;
    padding: 8px 16px;
    width: 100%;
    max-width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .container-datatabel-shimmer1{
    min-height: 200px;
    box-shadow: 0 1px 4px 0 rgba(255, 255, 255, 0.2);
    background-color: white;
    border-radius: 4px;
    padding: 8px 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  
.container-shimmer_title{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 9.6px 0px;
    width: 100%;
    max-width: 1024px;
}
.container-shimmer_title1{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 9.6px 0px;
    width: 100%;
}
.container-shimmer_table_title{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    padding: 8.5px 0px;
}
.container-shimmer_table_title1{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 20px;
    padding: 8.5px 0px;

}
.container-shimmer_table_title1{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 8.5px 0px;

}

.shimmer-dropdown{
    width:120px; 
    text-align: left;
    margin-left: px;
    display: flex;
    flex-direction: column;
}

.shimmer-dropdown1{
    width:120px; 
    text-align: left;
    display: flex;
    flex-direction: column;
}

.div-dropdown-shimmer{
    width:145px; 
    height: 30px;
    margin-top: 3px;
    margin-left: 8px;
}

.shimmer-title-text{  
    width:100%; 
    text-align: left;
    margin-left: 5px;
    height: 33px;
    padding: 6px 0px;
}

.shimmer-title-text-start{
    width:100%; 
    text-align: left;
    margin-left: 5px;
    height: 33px;
    padding: 6px 10px; 
}

.div-dropdown-title{
    height: 14px;
    border-radius: 4px;
    max-width:90px ;
 }
 
.div-title-shimmer{
    height: 16px;
    min-width:100% ;
    margin-right: 8px;
 }

 .shimmer-title-text-mid{
    flex-grow: 1;
 }

 .div-title-shimmer1{
    height: 14px;
    min-width:120px ;
    margin-right: 8px;
 }

 .shimmer-title-text-end{
    width: 100%;
    height: 40px;
    text-align: right;
 }

 .div-title-end{
    min-width: 115px;
    height: 35px;
    margin-right: 3.4vw;
 }


 
 .div-title-search{
    min-width: 165px;
    max-width:55px;
    height: 30px;
 }

 .div-title-search1{
    min-width: 105px;
    height: 30px;
  }
 

 .div-text-end{
    min-width:110px;
    height: 16px;
    border-radius: 4px;
 }

.shimmer_table_title{
    font-style: normal;
    width: 100%;
    justify-content: center;
}

.shimmer_table_title-end{
    font-style: normal;
    width: 100%;
    justify-content: center;
}





.shimmer_table_row{
    font-style: normal;
    width: 100%;
    height: 16px;
    justify-content: center;
}