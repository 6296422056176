@import url('https://fonts.googleapis.com/css?family=Manrope');
@import url('https://fonts.googleapis.com/css?family=Heebo');

.div-visit-button{
    width: 100%;
    max-width: 180px;
    text-align: left;
    justify-content: left;

}
.visit-button {
    max-width: 180px;
    padding: 10px 20px;
    font-weight: 500;
    border-radius: 10px;
    letter-spacing: 1px;
    align-self: left;
    margin: 0px 0px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    text-align: left;
    color: #FFFFFF;

}