@import url('https://fonts.googleapis.com/css?family=Manrope');
@import url('https://fonts.googleapis.com/css?family=Heebo');

@font-face {
    font-family: SF Pro Display;
    src: url(../../../assests/fonts/SF-Pro-Display-Regular.ttf);
}


.contact-container {
    background-color: #f6f8fb;
    margin-top: 0px;
    text-align: center;
    padding: 0 5.5%;

}

.container-home-contact {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding-top: 40px;
}


.contactus-home {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #6E6681;
    display: flex;
    align-items: center;
    flex-direction: row;
}


.text-contactus {
    font-family: 'Heebo';
    font-style: normal;
    font-size: 16px;
    display: flex;
    text-align: left;
    font-family: 'Manrope';
    font-weight: 600;
    color: #251A43;
}

.text-contact-top {
    padding-top: 14px;
    max-width: 490px;
    width: 100%;
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    color: #564D6D;
    text-align: center;
    padding-bottom: 14px;
}


.contact-bottom{
    padding-top: 14px;
    padding-bottom: 100px;
    justify-content: center;
    margin-top: 34px;
}
.contact-bottom-text{
    width: 100%;
    display: inline-block;
    max-width: 720px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #564D6D;
    text-align: justify;
}
