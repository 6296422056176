@import url('https://fonts.googleapis.com/css?family=Manrope');
@import url('https://fonts.googleapis.com/css?family=Heebo');
.top-bar-dahsboard {
    background: url('./../../../assests/topbar/MessagePage.svg') no-repeat center center/cover;
    padding-bottom: 4.5%;
    padding: 4.5% 5.5% 4.5% 5.5%;
    text-align: -webkit-center;
    text-align: -moz-center;
}

.top-bar_inner {
    display: flex;
    max-width: 1276px;
    flex-direction: row;
}

.text-wrapper-browser {
    text-align: left;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.text-wrapper-mobile {
    padding-top: 8vh;
    text-align: center;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

h1 {
    font-size: 44px;
    background-color: #f3ec78;
    text-align: left;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    background-image: linear-gradient(90deg, #af6a42, #f3ec78);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
}

.text-header {
    max-width: 550px;
    left: 0px;
    top: 0px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    color: #FFFFFF;
    margin: 0px 0px;
    letter-spacing: 0;
}

.text-footer-browser {
    max-width: 616px;
    left: 0px;
    padding-right: 40px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.04em;
    color: #B9C7DF;
    margin: 0px 0px;
    text-align: left;
}

.text-footer-mobile {
    max-width: 616px;
    left: 0px;
    padding-right: 40px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 0px;
    letter-spacing: 0.04em;
    color: #B9C7DF;
    margin: 16px 0px;
    text-align: center;
    justify-content: center;
}

.right-box-component {
    display: flex;
    flex-direction: row;
}

.div-message-card-title {
    position: relative;
    max-width: 413px;
}



.div-container-whatsapp {
    width: 100%;
    left: 0px;
    top: 0px;
    text-align: start;
    max-lines: 2;
    font-family: 'Manrope';
    font-style: normal;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.04em;
    color: #0D002E;
}


.div-text-left {
    font-family: 'Manrope';
    height: 40px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    position: absolute;
    z-index: 2;
    top: 22px;
    left: 0px;
    align-items: center;
    background-color: #FFFFFF;
    margin-left: 32px;
    padding: 0px 8px;
    border-radius: 10px;
    gap: 5px;
    transform: translateY(-2px) scale(1.005) translateZ(0);
    box-shadow: 0 24px 46px rgba(0, 0, 0, 0.11), 0 24px 46px var(--box-shadow-color);
}


.div-number-border {
    max-width: 176px;
    width: 168px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: -2px;
    padding: 0px 10px;
    position: absolute;
    border: 1px solid #D7CCE4;
    top: 0px;
    right: 0px;
}

.div-number {
    max-width: 186px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: -2px;
    padding: 0px 10px;
    background-image: linear-gradient(to right, #2CC3D8, #497AF9);
    position: absolute;
    top: 4px;
    right: 4px;
}


.div-text-right {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.div-time-indicator {
    display: flex;
    flex-direction: column;
    margin-top: 10vh;
    align-items: flex-start;
}

.div-time-title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    padding-left: 10px;
    max-lines: 1;
    color: #FFFFFF;
}

.div-card {
    width: 100%;
    z-index: 2px;
    padding-top: 52px;
}

.div-card-container {
    min-height: 155px;
    max-width: 380px;
    background: #FFFFFFE5;
    padding: 10px 10px;
    border-radius: 10px;
    border: 3px solid #8D40A0;
    position: relative;
}

.divider {
    width: 100%;
    height: 0px;
    left: 0px;
    border: 1px solid #D7CCE4;
    order: 1;
    flex-grow: 0;
    margin: 5px 0px;
}