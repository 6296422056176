@import url('https://fonts.googleapis.com/css?family=Heebo');

.card1 {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  align-items: center;
  background: #F6F9F3;
  box-shadow: 0px 24px 28px -4px rgba(66, 56, 95, 0.2);
  border-radius: 12px;
  max-width: 712px;
  padding: 0 2rem 2rem 2rem;
  border-radius: 10px;
}

.card2{
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  align-items: center;
  background: #F6F9F3;
  box-shadow: 0px 24px 28px -4px rgba(66, 56, 95, 0.2);
  width: 100%;
}

.div_opinion-line-top{
  width: 706px;
  height: 4px;
  margin-top: 1px;
  background: #4C9610;
  border-radius: 12px 12px 0px 0px;
}

.div_opinion-line-top2 {
  width: 100%;
  height: 4px;
  margin-top: 1px;
  background: #4C9610;
  border-radius: 12px 12px 0px 0px;
}

.div-opinion-container{
  flex-grow: 1; 
  display: flex;
  flex-direction: row; 
  padding: 44px 44px 44px 38px;
  gap: 13px;
}

.div-opinion-container-left{
  margin: 14px 4px
}

.div-opinion-container-right{
  display: flex;
  flex-direction: column;
  flex-grow: 1; 
  align-items: flex-start;
}


.div-text-opinion {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  color: #3D3358;
  text-align: left;
  flex-grow: 1;

}

.div-text-opinion2 {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  color: #3D3358;
  text-align: left;
  flex-grow: 1;

}


.div-text-user-details {
  display: flex;
  flex-direction: row;
  margin: 12px 0px;
}

.div-text-name {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #3D3358;
}

.div-text-company {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #1A63F4;
}

.div-text-designation {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #6E6681;
}

.div-avatar{
  width: 100%;
  text-align: left;
}