@import url('https://fonts.googleapis.com/css?family=Manrope');
@import url('https://fonts.googleapis.com/css?family=Poppins');

footer {
    background: linear-gradient(94.49deg, #170729 0%, #24082C 100%);
    height: 200px;
    width: 100%;
    bottom: 0%;
    position: static;
    text-align: center;
    margin: 0 0 -50px 0;
    padding: 0px 5.5%;
    text-align: -webkit-center;
    text-align: -moz-center;
}

.container-footer-mobile{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1276px;
    height: 200px;
    gap:10px
}


.footer-top {
    text-align: center;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
}

.footer-bottom {
    text-align: center;
    align-items: center;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #613494;
}

.footer-logo {
    height: 33px;
    width: 33px;
    object-fit: cover;
    margin-right: 5px
}

.div-title-top {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    padding: 0px 5px;
    color: #FFFFFF;
}

.copyright-mobile {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    padding: 0px 0px;
    text-align: center;
    color: #B7B3C1;
}

.footer-policy {
    font-size: 16px;
    font-family: 'Manrope';
    font-style: normal;
    flood-color: #fff;
    color: white;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    padding-right: 16px;
    text-decoration: none;

}

.footer-contactus {
    font-size: 16px;
    font-family: 'Manrope';
    font-style: normal;
    flood-color: #fff;
    color: white;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    padding-left: 16px;
    text-decoration: none;
}