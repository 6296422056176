@import url('https://fonts.googleapis.com/css?family=Manrope');
@import url('https://fonts.googleapis.com/css?family=Poppins');


.div-home-privacy{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 60px;
}

.container_smslist {
    background-color: #f6f8fb;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 5.5% 220px 5.5%;
}

.container_tabel {
    min-height: 375px;
    max-width: 1276px;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0px 5.5%;
}

.container_tabel1 {
    width:  100%;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.container-datatabel {
    min-height: 200px;
    box-shadow: 0 1px 4px 0 rgba(255, 255, 255, 0.2);
    padding: 8px 16px;
    background-color: white;
    border-radius: 4px;
    width: 100%;
    max-width: 1024px;
}
.container-datatabel1 {
    min-height: 200px;
    box-shadow: 0 1px 4px 0 rgba(255, 255, 255, 0.2);
    padding: 8px 16px;
    background-color: white;
    border-radius: 4px;
    width: 100%;
}

.container-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 9.5px 0px;
    width: 100%;
    max-width: 1024px;
}

.container-title1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 9.5px 0px;
    width: 100%;
}

.container-title-text-start {
    font-family: 'Manrope';
    font-size: 16px;
    color: #6E6681;
    padding-left: 15px;
    text-align: left;
    font-style: normal;
    font-family: 'Manrope';
    font-weight: 500;
    font-size: 16px;  
    width: 100%;
}

.container-title-text-middle {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    width: 100%;
    padding-left: 5px;
    text-align: left;
    color: #0D002E;
}

.container-button {
    width: 100%;
    text-align: end;
    height: 40px;
    padding-right: 3.4vw;

}

.container-text {
    margin-left: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    text-align: left;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #251A43;
}

th {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #251A43;
    text-align: left;
}

td {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-left: 50px;
    max-width: 150px;
    color: #564D6D;
    text-align: left;
}

.container-image {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 60px 0px;
    gap: 12px;
}

.img-empty {
    width: 44px;
    height: 44px;

}

.empty-msg {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #6E6681;
}

.div-title-empty-messsage {
    display: flex;
    padding: 0px;
    gap: 1px;
    width: 100%;
    max-width: 1024px;
    background: #F3F2F4;
   box-shadow: 0px -1px 0px rgba(136, 136, 136, 0.25), 0px 1px 0px rgba(136, 136, 136, 0.25);
}

.div-title-empty-messsage1 {
    display: flex;
    padding: 0px;
    gap: 1px;
    width: 100%;
    background: #F3F2F4;
   box-shadow: 0px -1px 0px rgba(136, 136, 136, 0.25), 0px 1px 0px rgba(136, 136, 136, 0.25);
}