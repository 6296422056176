.div-testimonials {
    background-color: #FFFFFF;
    text-align: -webkit-center;
    text-align: -moz-center;
    margin-top: -12px;
    padding: 120px 5.5%;
    position: relative;

}

.div-testimonials-inner {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.div-testimonials-opinion {
    max-width: 100%;
    margin-bottom: 80px;
    margin-top: 60px;
    justify-content: center
}