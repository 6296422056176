@import url('https://fonts.googleapis.com/css?family=Manrope');

.div-component-title {
    border-radius: 25px;
    justify-content: center;
    align-items: center;
    padding: 8px 14px;
    max-width: 107px;
    height: 33px;
    background: #e8defb;
    border-radius: 20px;
}

.component-title {
    height: 17px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
    color: #9d68f3;
}