.number-list{
    max-width: 100%;
    text-align: -webkit-center;
    text-align: -moz-center;
    padding: 0px 5.5%; 
}

.div-hr-line-browser{
    color: #A6ACAF;
    background-color:#A6ACAF;
    width:100%;
    height: 1;
    margin: 0px 0px;
}


.div-hr-line-mobile{
    color: #A6ACAF;
    background-color:#A6ACAF;
    width:100%;
    height: 1;
    margin: 0px 0px;
}