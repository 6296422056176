@import url('https://fonts.googleapis.com/css?family=Manrope');

.div-process {
    text-align: -webkit-center;
    background: url('./../../assests//process/process-background.svg') no-repeat center center/cover;
    background-size: cover;
    margin-top: 80px;
    text-align: -moz-center;
    justify-content: center;
    padding: 90px 5.5%;
}

.div-process-inner {
    max-width: 1276px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.div-process-title {
    justify-content: center;
    padding: 8px 14px;
    width: 85px;
    height: 33px;
    background: #390179;
    border-radius: 20px;
}

.title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 140%;
    text-transform: uppercase;
    color: #FFFFFF;
}

.div-process-header {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    color: #FFFFFF;
}

.div-process-steps-browser{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.div-process-steps-mobile{
    display: flex;
    flex-direction: column;
    gap: 10;
    justify-content: center;
    align-items: center;
}


.div-process1-browser{
    max-width: 400px;
    max-height: 336px;
    margin-top: 77px;
}

.div-process1-mobile{
    max-width: 400px;
    max-height: 336px;
}

.div-process2{
    max-width: 400px;
    max-height: 336px;
}
.div-process3-browser{
    max-width: 400px;
    max-height: 336px;
    margin-top: 77px;
}

.div-process3-mobile{
    max-width: 400px;
    max-height: 336px;
}

