@import url('https://fonts.googleapis.com/css?family=Poppins');
@import url('https://fonts.googleapis.com/css?family=Manrope');

.header-container {
    padding-bottom: 0px;
    width: 100%;
    z-index: 4;
    top: 0%;
    left: 0%;
    right: 0%;
    display: inline-block;
    background: #FFFFFF;
    position: sticky;
    text-align: -webkit-center;
    text-align: -moz-center;
    text-align: -moz-center;
    -moz-box-align: center;
    padding: 0px 5.5%;
    box-shadow: 0px 4px 16px rgba(35, 30, 36, 0.04);
}

.header-container-inner {
    align-items: center;
    display: flex;
    max-width: 1276px;
    justify-content: space-between;
    min-height: 60px;
    background: #FFFFFF;
}

.navbar-left {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #251A43;
    cursor: pointer
}

.logo-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #0F1825; 
}

.navbar-middle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1vw;
}

.navbar-middle-row-faq {
    min-width: 60px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #1A294F;
    text-decoration: none
}

.navbar-middle-row-privacy {
    margin-left: 15px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #1A294F;
    text-decoration: none
}

.nav-bar_end {
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
    text-align: center;
    gap: 20px;
    font-family: 'Manrope';
    font-style: normal;
    font-size: 15px;   
    font-weight: 600;
}