
@import url('https://fonts.googleapis.com/css?family=Manrope');
@import url('https://fonts.googleapis.com/css?family=Heebo');

.privacy-container {
    position: relative;
    margin-top: 0px;
    max-width: 720px;
    padding: 0px 5.5%;
    display: inline-block;
}

.container-home-privacy {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
}

.container-date {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-top: 34px;
    color: #564D6D;
    text-align: left;
}

.privacy-policy-home {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #6E6681;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.privacy-policy {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #251A43;

}

.privacy-text-privacy {
    padding-top: 14px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #564D6D;
    text-align: left;
}

.privacy-text-title {
    text-align: left;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: #292435;
    margin-top: 32px;
    margin-bottom: 12px;
}

.privacy-text-subtitle{
    text-align: left;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: #564D6D;
    margin-top: 26px;
    margin-bottom: 12px; 
}

.text-contact-top {
    padding-top: 14px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    color: #564D6D;
    text-align: left;
    padding-bottom: 14px;
}

.text-contact-bottom {
    padding-top: 14px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    color: #564D6D;
    text-align: justify;
    padding-bottom: 100px;
}